<template>
  <div>
    <v-card class="my-10" flat>
      <v-card-title>
        <span>A Card Title</span>
        <v-spacer></v-spacer>
        <v-btn color="accent" icon text> <v-icon>mdi-pencil-box</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        Text inside of <code>v-card-text</code> component.
      </v-card-text>
    </v-card>
    <v-card class="my-10" flat>
      <v-card-title>Headings inside <code>v-card-text</code></v-card-title>
      <v-card-text>
        <h1>Heading One</h1>
        <h2>Heading Two</h2>
        <h3>Heading Three</h3>
        <h4>Heading Four</h4>
        <h5>Heading Five</h5>
        <h6>Heading Six</h6>
      </v-card-text>
    </v-card>
    <v-card class="my-10" flat>
      <v-card-title
        >Typography classes inside of <code>v-card text</code></v-card-title
      >
      <v-card-text>
        <p class="text-h1">Heading One</p>
        <p class="text-h2">Heading Two</p>
        <p class="text-h3">Heading Three</p>
        <p class="text-h4">Heading Four</p>
        <p class="text-h5">Heading Five</p>
        <p class="text-h6">Heading Six</p>
        <p class="text-subtitle-1">Subtitle One</p>
        <p class="text-subtitle-2">Subtitle Two</p>
        <p class="text-body-1">Body One</p>
        <p class="text-body-2">Body Two</p>
        <p class="text-button">Button</p>
        <p class="text-caption">Caption</p>
        <p class="text-overline">Overline</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Typography",
};
</script>
