<template>
  <div>
    <p>
      <v-btn
        color="primary"
        text
        @click="
          () => {
            isOpen1 = true;
          }
        "
        >Basic dialog</v-btn
      >
    </p>
    <p>
      <v-btn
        color="primary"
        text
        @click="
          () => {
            isOpen2 = true;
          }
        "
        >Multiple cards in dialog</v-btn
      >
    </p>
    <v-dialog
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      v-model="isOpen1"
      max-width="500"
    >
      <v-card class="rounded-0">
        <v-card-title>
          <h4 class="white--text">Vuetify Card Title</h4>
        </v-card-title>
        <v-card-text class="body-2 white--text font-weight-light">
          <p>
            This dialog is created with a Vuetify Dialog component, with a
            Vuetify Card component inside of it. All of the content is inside of
            the card.
          </p>

          <p>
            Dialogs need to have a max-width attribute set, otherwise they will
            fill all of the available space on the screen. This is consistent
            with all of the examples in the Vuetify docs.
          </p>

          <p>
            This is a <code>&lt;v-card-text&gt;</code> functional component of
            the Vuetify <code>Card</code> component. To style this the text, the
            element has the <code>body-2</code>, <code>white--text</code> and
            <code>font-weight-light</code> Vuetify classes applied.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      v-model="isOpen2"
      max-width="500"
    >
      <v-card class="rounded-0">
        <v-card-title>
          <h4 class="white--text">Card with primary background</h4>
        </v-card-title>
        <v-card-text class="body-2 white--text font-weight-light">
          These cards have the class <code>rounded-0</code> applied to remove
          border-radius, allowing the edges of the cards to meet and letting the
          dialog component handle the border-radius of the dialog as a whole.
        </v-card-text>
      </v-card>
      <v-card color="primary" class="rounded-0">
        <v-card-title>
          <h4 class="white--text">Card with secondary background</h4>
        </v-card-title>
        <v-card-text class="body-2 white--text font-weight-light">
          Eget magna fermentum iaculis eu non diam phasellus vestibulum lorem
          sed risus ultricies tristique nulla aliquet enim tortor at auctor urna
          nunc id cursus metus aliquam eleifend mi in nulla posuere sollicitudin
          aliquam
        </v-card-text>
      </v-card>
      <v-card color="info" class="rounded-0">
        <v-card-title>
          <h4 class="white--text">Card with info background</h4>
        </v-card-title>
        <v-card-text class="body-2 white--text font-weight-light">
          Eget magna fermentum iaculis eu non diam phasellus vestibulum lorem
          sed risus ultricies tristique nulla aliquet enim tortor at auctor urna
          nunc id cursus metus aliquam eleifend mi in nulla posuere sollicitudin
          aliquam
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Dialogs",
  data: () => ({
    isOpen1: false,
    isOpen2: false,
  }),
};
</script>
