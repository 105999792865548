<template>
  <v-data-table
    :headers="headers"
    :items="breaches"
    :items-per-page="5"
    class="elevation-0"
    hide-default-footer
  ></v-data-table>
</template>

<script>
export default {
  name: "DataTable",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Acquired", value: "acquire_date" },
      { text: "Status", value: "status" },
    ],
    breaches: [
      {
        name: "Breach Name One",
        acquire_date: "2020-12-12",
        status: "Normalization Needs Review",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
      {
        name: "Breach Name Two",
        acquire_date: "2020-12-12",
        status: "Complete",
      },
    ],
  }),
};
</script>
