<template>
  <div>
    <toolbar />
    <v-row class="px-5">
      <v-col cols="3">
        <v-text-field label="Search"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          :items="['All', 'Enabled', 'Trial', 'Disabled']"
          label="Status"
        ></v-select>
      </v-col>
    </v-row>

    <v-date-picker no-title></v-date-picker>

    <data-table />
    <typography />

    <v-card flat>
      <v-card-title>
        <span>Key Value List</span>
        <v-spacer></v-spacer>
        <v-btn color="accent" icon text> <v-icon>mdi-pencil-box</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="font-weight-thin">Font weight thin</v-col>
              <v-col cols="6" class="font-weight-light"
                >Font weight light</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="3" class="font-weight-thin"> Description </v-col>
          <v-col cols="9" class="font-weight-light">
            Morbi mattis ullamcorper velit. Donec orci lectus, aliquam ut,
            faucibus non, euismod id, nulla. Fusce convallis metus id felis
            luctus adipiscing. Aenean massa. Vestibulum purus quam, scelerisque
            ut, mollis sed, nonummy id, metus. Nulla consequat massa quis enim.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-10" flat>
      <v-card-title>
        <span>File Management</span>
        <v-spacer></v-spacer>

        <v-btn color="accent" icon text
          ><v-icon color="accent">mdi-plus-circle</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <p>
          Soluta eos quia aut et voluptatem et. Et est totam et ipsam sunt.
          Voluptatibus culpa facere omnis quia quaerat. Totam quia et minima
          ipsa sed id maiores et. Soluta facilis sed quam. Voluptas ad amet
          autem adipisci aut.
        </p>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="4">
                <v-btn class="mt-5">
                  <span>Sample_file_01</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <v-icon color="white" class="float-right"
                    >mdi-close-circle</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col cols="8">
                <p class="mt-7 mx-10 info--text">Uploaded on June 14, 2021</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn class="mt-5">
                  <span>Sample_file_02</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <v-icon color="white" class="float-right"
                    >mdi-close-circle</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col cols="8">
                <p class="mt-7 mx-10 info--text">Uploaded on June 14, 2021</p>
              </v-col>
            </v-row>

            <v-file-input class="mt-5"></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-10" flat>
      <v-card-title>
        <span>Timeline</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-timeline align-top dense>
          <v-timeline-item icon="mdi-check-bold" fill-dot small>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text-subtitle-1 mb-0">Paritioning started</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <p class="ml-5 mb-0 text-caption">June 14, 2021</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <p class="ml-5 mb-0 text-body-2">
                      Molestiae amet voluptatibus voluptate ipsa aspernatur
                      autem a ut. Officiis ipsum ea nesciunt ut cum sunt.
                      Blanditiis et ut sit ut quae. Quam cum ut occaecati
                      dolores illo necessitatibus.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item icon="mdi-check-bold" fill-dot small>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text-subtitle-1 mb-0">Paritioning in progress</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <p class="ml-5 mb-0 text-caption">June 14, 2021</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <p class="ml-5 mb-0 text-body-2">
                      Molestiae amet voluptatibus voluptate ipsa aspernatur
                      autem a ut. Officiis ipsum ea nesciunt ut cum sunt.
                      Blanditiis et ut sit ut quae. Quam cum ut occaecati
                      dolores illo necessitatibus.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item icon="mdi-check-bold" fill-dot small>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text-subtitle-1 mb-0">Paritioning complete</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <p class="ml-5 mb-0 text-caption">June 14, 2021</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <p class="ml-5 mb-0 text-body-2">
                      Molestiae amet voluptatibus voluptate ipsa aspernatur
                      autem a ut. Officiis ipsum ea nesciunt ut cum sunt.
                      Blanditiis et ut sit ut quae. Quam cum ut occaecati
                      dolores illo necessitatibus.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item small flat color="secondary">
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text-subtitle-1 mb-0">
                      Normalization needs review
                    </p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <p class="ml-5 mb-0 text-caption">June 14, 2021</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea filled flat></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <dialogs />

    <v-card class="my-10" flat>
      <v-card-title>
        <span>Inputs in a card</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Search"></v-text-field>
            <v-select
              :items="['All', 'Enabled', 'Trial', 'Disabled']"
              label="Status"
            ></v-select>
            <v-checkbox label="I am a checkbox"></v-checkbox>
          </v-col>
        </v-row>
        <v-textarea filled flat></v-textarea>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn color="accent" class="float-right">Submit</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center">
      <v-btn color="primary" class="white--text" @click="overlay = !overlay">
        View Overlay
      </v-btn>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import DataTable from "./DataTable";
import Dialogs from "./Dialogs";
import Toolbar from "./Toolbar";
import Typography from "./Typography";
export default {
  name: "Style",
  data: () => ({
    overlay: false,
  }),

  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  components: {
    DataTable,
    Dialogs,
    Toolbar,
    Typography,
  },
};
</script>
