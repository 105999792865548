<template>
  <v-toolbar flat>
    <v-toolbar-title class="text-h1">Tracelight UI Style Guide</v-toolbar-title>
    <v-spacer />
    <v-tooltip class="darken-2" max-width="250" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="accent" icon text fab>
          <v-icon> mdi-information-outline</v-icon></v-btn
        >
      </template>
      <p>
        The Style Guide is where we develop our Vuetify theme and components,
        and provides a reference for how to render various components.
      </p>
      <p>
        For example, we can customize the background color of tooltips by
        changing the <code>$tooltip-background-color</code> SASS variable, and
        then we can see the changes on this page.
      </p>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  name: "Toolbar",
};
</script>
